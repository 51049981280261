import firebase from 'firebase/app'

export default async function addData(payload) {
  const { collection, data } = payload
  try {
    const user = await firebase.auth().currentUser
    const promise = await firebase.firestore().collection(collection).doc()
    data.docID = promise.id
    data.uid = user.uid
    data.insertTime = new Date()
    window.console.log(user.uid.length)
    await firebase.firestore().collection(collection).doc(promise.id).set(data, { merge: true })

    const response = {
      status: 'success',
    }
    return response
  } catch (error) {
    const response = {
      status: 'error',
      msg: error,
    }
    return response
  }
}
