<template>
  <div>
    <h2>求人情報の編集</h2>
    <div class="">
      テキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキスト
    </div>
    <b-card
      title=""
    >
      <b-form>
        <b-row
          v-for="(item, index) in menulist"
          :key="index"
        >
          <InputParts
            v-if="useValueDicList.indexOf(item.type) !== -1"
            :label="item.label"
            :type="item.type"
            :choice="typeof item.choice !== 'undefined'? item.choice: []"
            :value-dic="inputForm[item.key]"
            :default-value="typeof item.defaultValue !== 'undefined'? item.defaultValue: []"
            :disabled="typeof item.disabled !== 'undefined'? item.disabled: false"
            :explain="typeof item.explain !== 'undefined'? item.explain: []"
            :select="item.type ==='checkbox' ? choice: []"
            @inputData="inputForm[item.key] = $event"
          />
          <InputParts
            v-else
            :label="item.label"
            :type="item.type"
            :choice="typeof item.choice !== 'undefined'? item.choice: []"
            :value="item.type !=='checkbox' ?inputForm[item.key]: ''"
            :default-value="typeof item.defaultValue !== 'undefined'? item.defaultValue: []"
            :disabled="typeof item.disabled !== 'undefined'? item.disabled: false"
            :explain="typeof item.explain !== 'undefined'? item.explain: []"
            :select="item.type ==='checkbox' ? choice: []"
            @inputData="inputForm[item.key] = $event"
          />
        </b-row>
        <b-row>
          <b-col offset-md="4">
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="reset"
              variant="outline-secondary"
              class="mr-1"
              @click="cancel"
            >
              キャンセル
            </b-button>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              @click="confirm"
            >
              登録する
              <b-spinner
                v-if="status === 2"
                small
              />
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </b-card>
  </div>
</template>

<script>
import Ripple from 'vue-ripple-directive'
import {
  BRow, BCol, BForm, BButton, BCard, BSpinner,
} from 'bootstrap-vue'
import InputParts from '@/components/molecules/input/InputParts.vue'
import { mapGetters } from 'vuex'
import alphaList from '@/components/conf/JPCAREER様_就労PF_求人企業_新規作成1_基本情報.json'
import betaList from '@/components/conf/JPCAREER様_就労PF_求人企業_新規作成1_その他情報.json'
import mergeByDocID from '@/firestore/data/updateSingle'
import addData from '@/firestore/data/create'

export default {
  components: {
    BRow,
    BCol,
    BForm,
    BButton,
    BCard,
    InputParts,
    BSpinner,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      inputForm: {},
      status: 1,
      isShow: false,
      alphaList,
      betaList,
      menulist: [],
      useValueDicList: ['checkbox', 'selectSingle'],
    }
  },
  computed: {
    ...mapGetters('jobApplicantBasic', ['jobOpeningsList']),
  },
  async mounted() {
    // 本来はここで再度Firestoreからgetした方が良い🔥
    if (this.jobOpeningsList !== 'undefined' && this.$route.query.met === 'edit') {
      const index = this.jobOpeningsList.findIndex(v => v.docID === this.$route.query.id)
      this.inputForm = this.jobOpeningsList[index]
    }
    if (this.$route.query.met === 'edit') {
      // ここでFirestoreから取得：idを元に
    }
    switch (this.$route.query.type) {
      case 'alpha':
        this.menulist = this.alphaList
        break
      case 'beta':
        this.menulist = this.betaList
        break
      default:
        break
    }
  },
  methods: {
    async confirm() {
      // window.console.log('🐶', this.inputForm)
      this.status = 2
      const data = {
        collection: 'jobOpenings',
        data: this.inputForm,
      }
      if (this.$route.query.met === 'edit') {
        const response = await mergeByDocID(data)
        if (response.status === 'success') {
          this.success()
        } else {
          window.console.log('error', response.msg)
        }
      } else {
        const response = await addData(data)
        if (response.status === 'success') {
          this.success()
        } else {
          window.console.log('error', response.msg)
        }
      }
    },
    showBtn() {
      this.$ref.sbchild.$emit('success')
    },
    success() {
      this.$swal({
        title: '登録完了！',
        text: 'データが正常に登録されました。',
        icon: 'success',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      }).then(() => {
        this.move()
        this.status = 1
      })
    },
    cancel() {
      this.move()
    },
    move() {
      if (this.$route.query.met === 'edit') {
        this.$router.push({ path: '/job-jobapplicant', query: { id: this.$route.query.id } })
      } else {
        this.$router.push({ name: 'job-jobapplicant-list' })
      }
    },
  },
}
</script>
