import firebase from 'firebase/app'
import store from '@/store'

// createで作成済みのものを更新する時
export default async function mergeByDocID(payload) {
  const { collection, data } = payload
  try {
    const uid = store.getters['userBasic/uid']
    const email = store.getters['userBasic/mail']

    data.updateTime = new Date()
    data.updateUid = uid
    data.updateMail = email
    await firebase.firestore().collection(collection).doc(data.docID).set(data, { merge: true })

    const response = {
      status: 'success',
    }
    return response
  } catch (error) {
    const response = {
      status: 'error',
      msg: error,
    }
    return response
  }
}
