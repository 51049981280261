<template>
  <b-row class="match-height">
    <b-col lg="8">
      <EditJobInfo />
    </b-col>
    <b-col lg="4">
      <Sidebar />
    </b-col>
  </b-row>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'
import EditJobInfo from '@/components/company/jobOpenings/EditJobInfo.vue'
import Sidebar from '@/components/company/sidebar/test/Sidebar.vue'

export default {
  components: {
    BRow,
    BCol,
    EditJobInfo,
    Sidebar,
  },
}
</script>
